<template>
    <van-action-sheet v-model="dialogVisible" title="预约发货时间">
        <div class="pad bg_orange fc_orange fs28">
            提货保障时间为下单后2个小时内,若您期望的时间我们无法送达,稍后物流管家会致电您确认。
        </div>
        <van-tree-select
            height="55vw"
            :items="items"
            @click-nav="handleNav"
            @click-item='handleItem'
            :active-id.sync="activeId"
            :main-active-index.sync="activeIndex"
        />
    </van-action-sheet>
</template>
<script>
import selectData from './selectData.js'
export default {
    name:'DateTimeSelect',
    data(){
        return {
            dialogVisible:false,
            activeIndex:0,
            activeId:null,
            items:[]
        }
    },
    computed:{
        navRecord(){
            return this.items[this.activeIndex]
        }
    },
    methods:{
        show(){
            this.dialogVisible = true
            this.items = selectData()
            this.activeId = []
        },
        handleNav(index){
            this.activeIndex = index
        },
        handleItem(item){
            this.dialogVisible = false
            const obj = this.navRecord
            this.$emit('select', {nav: obj, child: item})
        }
    }
}
</script>
<style scoped>
    .pad{
        padding: 10px 20px
    }
    .bg_orange{
	background-color: #fff1e2;
    }
    .fc_orange{
        color:#f86b47;
    }
    .fs28{
        font-size: 28px;
    }

</style>