const WEEK_TEXT = ["日", "一", "二", "三", "四", "五", "六"]
const DATE_RANGE = 30 //预约时间，显示30天的所有日期
const TIME_RANGE = [
    {text: '10:00-10:30', id: '10', disabled: false },
    {text: '10:30-11:00', id: '10.5', disabled: false },
    {text: '11:00-11:30', id: '11', disabled: false },
    {text: '11:30-12:00', id: '11.5', disabled: false },
    {text: '12:00-12:30', id: '12', disabled: false },
    {text: '12:30-13:00', id: '12.5', disabled: false },
    {text: '13:00-13:30', id: '13', disabled: false },
    {text: '13:30-14:00', id: '13.5', disabled: false },
    {text: '14:00-14:30', id: '14', disabled: false },
    {text: '14:30-15:00', id: '14.5', disabled: false },
    {text: '15:00-15:30', id: '15', disabled: false },
    {text: '15:30-16:00', id: '15.5', disabled: false },
    {text: '16:00-16:30', id: '16', disabled: false },
    {text: '16:30-17:00', id: '16.5', disabled: false },
    {text: '17:00-17:30', id: '17', disabled: false },
    {text: '17:30-18:00', id: '17.5', disabled: false },
    {text: '18:00-18:30', id: '18', disabled: false },
    {text: '18:30-19:00', id: '18.5', disabled: false },
    {text: '19:00-19:30', id: '19', disabled: false },
    {text: '19:30-20:00', id: '19.5', disabled: false }
]
let tempList = []
function setCurDay(now){
    let weekStr
    const curDate = new Date(now)
    const today = new Date().getDate()  //今天的日期
    const month = ('0' + (curDate.getMonth() + 1)).slice(-2)
    const day = curDate.getDate()  // 左侧展示日期
    switch(day - today){
        case 0: weekStr = '今天'; break;
        case 1: weekStr = '明天'; break;
        case 2: weekStr = '后天'; break;
        default: weekStr = "周" + WEEK_TEXT[curDate.getDay()]; break;
    }
    
    return weekStr +'   '+ month + '月' + ('0' + day).slice(-2) + '日'
}
function setCurTime(timeIndex, dateTime){
    let arr = TIME_RANGE.map((item, index)=>{
        const obj={}
        obj.text = item.text
        obj.id = item.id
        obj.disabled = false
        return obj
    })
    return timeIndex > 0 ? arr : setTimeRange(dateTime)
}
function setTimeRange(dateTime){
    const nowtime = new Date(dateTime)
    const nowHour =  nowtime.getHours()
    const nowMinutes =  nowtime.getMinutes()
    let timeArr = []
    if(nowHour < 9){  //不到九点，全部都可以选择
        timeArr = TIME_RANGE
    }else if(nowHour >= 18){  //超过18点，只能联系客服
        timeArr = [{text:'请联系客服！', id:'noData', disabled: true}]
    }else{
        TIME_RANGE.map((item,index)=>{
            const isHalf = (Math.floor(nowMinutes/30) == 0) ? 0 : 0.5
            const fliterId = (nowHour+1) + isHalf
            if(fliterId <= item.id){
                timeArr.push(item)
            }
        })
    }
    return timeArr
}
function setDate(){
    const today = new Date()
    tempList = []
    for(let i=0; i < DATE_RANGE; i++){
        let newDay = today.getTime() + i* 24 * 60 * 60 * 1000
        let obj = {'text': setCurDay(newDay), 'children': setCurTime(i,newDay)}
        tempList.push(obj)
    }
    return tempList
}

function selectDate(){
    return setDate()
}

export default selectDate;