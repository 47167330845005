<template>
  <div class="place_order">
    <headNav></headNav>
    <div class="flex_m mtabs mb30" v-if="$store.state.drawerType === 'add'">
      <div class="tab_li flex1 active">普通发货</div>
      <div class="tab_li flex1" @click="jumpToBatchForm">批量发货</div>
    </div>
    <van-cell-group class="block_base">
      <van-cell center is-link @click="() => toAddress('deliver')">
        <template #title>
          <div class="ml20" v-if="newStepArr.sendersName && newStepArr.sendersPhone">
            {{ newStepArr.sendersName || '' }}
            {{ newStepArr.sendersPhone || '' }}
          </div>
          <div class="ml20 f888 pl10" v-else>请填写发货信息</div>
        </template>
        <template #icon>
          <van-tag type="primary" class="size26">发</van-tag>
        </template>
        <template #label v-if="newStepArr.sendersCityName">
          <div class="ml20 fz28 f333">
            {{ sendsAddr }}
          </div>
        </template>
      </van-cell>
      <van-cell center is-link @click="() => toAddress('receive')">
        <template #title>
          <div class="ml20 f333" v-if="newStepArr.recipientName && newStepArr.recipientPhone">
            {{ newStepArr.recipientName || '' }}
            {{ newStepArr.recipientPhone || '' }}
          </div>
          <div class="ml20 f888 pl10" v-else>请填写收货信息</div>
        </template>
        <template #icon>
          <van-tag type="success" class="size26">收</van-tag>
        </template>
        <template #label>
          <div class="ml20 fz28 f333" v-if="newStepArr.recipientCityName">
            {{ receiptAddr }}
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <CargoLabelCmp
      v-if="this.$store.state.cargoLabelList.length"
      typeRef="orderOneM"
      :newStepArr="newStepArr"
      :sp="newStepArr.sp || ''"
      :isEdit="isEditNew"
      @changeEdit="changeEdit"
      @changeSp="changeSp"
    ></CargoLabelCmp>
    <van-form @submit="onSubmit" ref="oneForm">
      <van-row
        type="flex"
        justify="center"
        class="van-hairline--bottom block_base mt30"
        v-show="isEditNew"
      >
        <van-col span="24" class="mt30 indent8">合计</van-col>
        <van-col span="8" class="col">
          <van-field
            v-model="newStepArr.totalPakages"
            name="totalPakages"
            placeholder="包装件数"
            class="h35 borRight ctx1"
            :disabled="isLock"
            :rules="regExp('integer')"
          />
        </van-col>
        <van-col span="8" class="col">
          <van-field
            v-model="newStepArr.goodsWeight"
            name="goodsWeight"
            placeholder="总重量"
            class="h35 borRight ctx2"
            :disabled="isLock"
            :rules="regExp('oneDec')"
          />
        </van-col>
        <van-col span="8" class="col">
          <van-field
            v-model="newStepArr.goodsVolume"
            name="goodsVolume"
            class="h35 ctx3"
            placeholder="总体积"
            :disabled="isLock"
            :rules="regExp('sixDec')"
          />
        </van-col>
      </van-row>
      <div class="providerbox mt20" v-if="newStepArr.drawerType !== 'add'">
        <div class="protitle">你选择的产品</div>
        <div class="flex_lr flex_b">
          <div class="procont mt10">
            <div class="protype red">
              {{
                newStepArr.biaoQian && newStepArr.biaoQian == 'jiaGeZuiDi'
                  ? '大票特惠'
                  : newStepArr.biaoQian && newStepArr.biaoQian == 'zhunShiDa'
                  ? '大票快递'
                  : newStepArr.biaoQian && newStepArr.biaoQian == 'shiXiaoZuiKuai'
                  ? '特快'
                  : newStepArr.truckTypeName
              }}
            </div>
            <div class="prodate">
              {{ newStepArr.sendersDate | theDay }}日提货，预计{{ newStepArr | days }}送达
            </div>
          </div>
          <div @click="() => jumpToPage('3')">收货标准</div>
        </div>
      </div>
      <van-row type="flex" justify="center" class="van-hairline--bottom mt30 block_base">
        <van-col span="12" class="col">
          <van-cell
            title="货物类型"
            is-link
            arrow-direction="down"
            :value="newStepArr.cargoTypeName"
            @click.stop="showRadioModal('货物类型', 'cargoType', cargoTypeVO, newStepArr.cargoType)"
          />
        </van-col>
        <van-col span="12" class="col">
          <van-cell
            title="包装方式"
            is-link
            arrow-direction="down"
            :value="newStepArr.packingName"
            @click.stop="showRadioModal('包装方式', 'packing', packingVO, newStepArr.packing)"
          />
        </van-col>
      </van-row>
      <van-cell-group class="block_base">
        <van-cell title="增值服务" is-link @click="popUp">
          <template #default
            ><span class="f333">{{ service }}</span></template
          >
        </van-cell>
        <van-cell
          is-link
          @click="chooseTime"
          class="reflex"
          v-if="newStepArr.drawerType !== 'edit'"
        >
          <template #title>预计发货时间</template>
          <template #default>
            <span class="f333"> {{ newStepArr.sendersDate }} {{ newStepArr.sendersTime }} </span>
          </template>
        </van-cell>
        <van-cell title="备注">
          <template #default>
            <van-field
              v-model="newStepArr.remark"
              name="remark"
              class="h35"
              placeholder="请填写托运备注"
            />
          </template>
        </van-cell>
      </van-cell-group>
      <van-row type="flex" justify="center">
        <van-col :span="2"
          ><van-checkbox
            v-model="agreementFlag"
            class="mt30"
            @click="changeAgreement"
          ></van-checkbox
        ></van-col>
        <van-col :span="16"
          ><div class="tips fz24">
            我已阅读并同意<span @click="() => jumpToPage('1')">《运输条款》</span
            ><span @click="() => jumpToPage('2')">《隐私政策》</span>
          </div></van-col
        >
      </van-row>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">选择产品</van-button>
      </div>
    </van-form>
    <VantFieldSelect :fatherObj="fatherObj" @getMessage="getMessage" />
    <ServiceProfit ref="serviceRef" @comfirm="updataService"></ServiceProfit>
    <DateTimeSelect ref="timeSelectRef" @select="getDateTime"></DateTimeSelect>
  </div>
</template>
<script>
import headNav from '@/components/mobile/headNav.vue'
import {
  getCargoLabelList,
  providerInfoAllN,
  getDetailStr,
  getZengZhiAll,
  getByCode,
  fetchPackingList,
  initSendersDate,
  initArea,
  getOrderDetial,
  areaFilterVersion,
  handleInquiryData,
  calculateWeight
} from '@/assets/js/order'
import { ORDERSTORE } from '@/assets/js/orderLibrary'
import { regExp } from '@/assets/js/global'
import {
  getOrderForm,
  setOrderForm,
  getAgreementState,
  setAgreementState
} from '@/assets/js/storage'
import ServiceProfit from './serviceProfit.vue'
import DateTimeSelect from './components/DateTimeSelect.vue'
import VantFieldSelect from './VantFieldSelect'
import CargoLabelCmp from '@/components/cargoLabelCmp/CargoLabelCmp.vue' //引入货物规格
import moment from 'moment'
import { getCookie } from '@/assets/js/cookies.js'
import { getUserStore } from '@/assets/js/storage'
const userData = getUserStore()
export default {
  name: 'stepOne',
  components: {
    ServiceProfit,
    DateTimeSelect,
    VantFieldSelect,
    CargoLabelCmp,
    headNav
  },
  data() {
    return {
      drawerType: 'add', //订单类型： add新增、edit修改、again再来一单。
      isLock: false, //是否已对账
      isBatch: false, //不是批量下单
      faIdx: -1, //批量下单的订单序号
      newStepArr: ORDERSTORE, //所有表单数据
      regExp: regExp,
      // service: "无需回单、送货、报价",
      agreementFlag: false,
      cargoTypeVO: this.$store.state.cargoTypeVO, //货物类型
      tempList: [], // 标签值的临时变量
      isEdit: true, //true为显示合计，false为显示规格明细
      fatherObj: {
        name: '',
        type: '',
        columns: [],
        selectCode: '',
        isShow: false
      }
    }
  },
  filters: {
    theDay(sendersDate) {
      let cur = moment().format('DD')
      let sendsDay = moment(sendersDate).format('DD')
      let day = sendsDay
      if (cur === sendsDay) {
        day = '今'
      }
      return day
    },
    days(item) {
      let sendersDate = item.sendersDate
      sendersDate = moment(sendersDate)
        .add(item.lineHour, 'hour')
        .format('MM月DD日')
      return sendersDate
    }
  },
  computed: {
    packingVO() {
      //包装方式列表
      return this.$store.state.byPCode.packingVO
    },
    sendsAddr() {
      let addr =
        (this.newStepArr.sendersProvinceName || '') +
        '' +
        (this.newStepArr.sendersCityName || '') +
        '' +
        (this.newStepArr.sendersDistrictName || '') +
        '' +
        (this.newStepArr.sendersDetailaddress || '')
      return addr
    },
    receiptAddr() {
      let addr =
        (this.newStepArr.recipientProvinceName || '') +
        '' +
        (this.newStepArr.recipientCityName || '') +
        '' +
        (this.newStepArr.recipientDistrictName || '') +
        '' +
        (this.newStepArr.recipientDetailaddress || '')
      return addr
    },
    service() {
      let str = getDetailStr(this.newStepArr, true)
      return str
    },
    addressObj() {
      const data = this.$route.query.addressObj
      if (data) {
        return JSON.parse(this.$route.query.addressObj)
      } else {
        return null
      }
    },
    isEditNew() {
      return this.isEdit
    }
  },
  created() {
    let cookieOrderStore = getCookie('orderNewStore') //询价页面带来的数据
    areaFilterVersion(this) //查询地址版本
    getByCode(this)
    this.agreementFlag = getAgreementState() === '1' ? true : false
    let routeParams = this.$route.params
    if (!!routeParams.isAgree) {
      this.agreementFlag = true
      setAgreementState('1')
    }
    if (!!routeParams.orderNo) {
      //修改或者再来一单
      let drawerType = routeParams.drawerType || 'edit'
      this.$store.state.drawerType = drawerType
      getOrderDetial(this, routeParams.orderNo, drawerType, this.callbackDetail) //获取订单详情
    } else if (!!routeParams.oneDatas) {
      this.newStepArr = routeParams.oneDatas
    } else {
      let newForm = getOrderForm()
      if (!newForm) {
        newForm = ORDERSTORE
      }
      if (cookieOrderStore) {
        //官网1.0快速询价下单参数
        let newCookie = handleInquiryData(cookieOrderStore)
        newForm = {
          ...ORDERSTORE,
          ...newCookie
        }
      }
      //设置初始预计发货时间段
      let sendsDate = initSendersDate()
      newForm = {
        ...newForm,
        ...sendsDate
      }
      this.newStepArr = newForm
      if (!cookieOrderStore) {
        initArea(this.areaCallback)
      }
    }
    let byPCode = this.$store.state.byPCode
    if (!byPCode.packingVO.length) {
      getZengZhiAll()
      fetchPackingList()
    }
    if (userData.corpId) {
      getCargoLabelList(userData.corpId) //获取货物规格列表
    }
  },
  methods: {
    callbackDetail(res) {
      if (!!res.orderSn && res.dzdState === '0') {
        this.isLock = true
      }
      this.newStepArr = res
    },
    changeAgreement() {
      setAgreementState(this.agreementFlag ? 1 : 0)
    },
    areaCallback(dataArr) {
      this.newStepArr = {
        ...this.newStepArr,
        ...dataArr.sendsArr,
        ...dataArr.receiveArr
      }
      this.newStepArr = { ...this.newStepArr }
      setOrderForm(this.newStepArr)
    },
    changeSp(item) {
      this.newStepArr = {
        ...this.newStepArr,
        ...item
      }
    },
    changeEdit(flag) {
      this.isEdit = flag || false
    },
    showRadioModal(name, type, lists, selectCode) {
      this.fatherObj = {
        name,
        type,
        columns: lists,
        selectCode,
        isShow: true
      }
    },
    getMessage(arr) {
      this.newStepArr = {
        ...this.newStepArr,
        ...arr
      }
    },
    popUp() {
      if (this.isLock) {
        this.$toast('已锁定订单，不能修改增值服务')
        return
      }
      setOrderForm(this.newStepArr)
      this.$refs.serviceRef.show(this.newStepArr)
    },
    chooseTime() {
      this.$refs.timeSelectRef.show()
    },
    // 进入收、发地址页面
    toAddress(type) {
      let navTitle = type == 'deliver' ? '添加发货地址' : '添加收货地址'
      this.$router.push({
        name: 'addressManage',
        meta: {
          title: navTitle
        },
        params: {
          isBatch: this.isBatch || false,
          faIdx: this.faIdx,
          addressType: type,
          optionType: 'change',
          addrDatas: this.newStepArr
        }
      })
    },
    // 获取增值服务的信息对象
    updataService(datas) {
      this.newStepArr = {
        ...this.newStepArr,
        ...datas
      }
      setOrderForm(this.newStepArr)
    },
    getDateTime(dateTime) {
      const year = new Date().getFullYear()
      const nav = dateTime['nav'].text
      const day = nav.split('   ')[1]

      const mm = day.split('月')[0]
      const dd = day.split('月')[1].split('日')[0]
      const timeRange = dateTime['child'].text
      this.newStepArr.sendersDate = `${year}-${mm}-${dd}`
      this.newStepArr.sendersTime = `${timeRange}`
    },
    async onSubmit(values) {
      let weightAndVolume = await calculateWeight(this.newStepArr, this)
      let datas = { ...this.newStepArr, ...weightAndVolume }
      if (!datas.sendersName || !datas.sendersPhone || !datas.sendersCityName) {
        this.$toast('请选择收货信息')
        return
      }
      if (!datas.recipientName || !datas.recipientPhone || !datas.recipientCityName) {
        this.$toast('请选择收货信息')
        return
      }
      if (!datas.cargoTypeName) {
        this.$toast('请选择货物类型')
        return
      }
      if (!datas.packing) {
        this.$toast('请选择包装方式')
        return
      }
      if (!datas.sendersDate || !datas.sendersTime) {
        this.$toast('请选择预计发货时间')
        return
      }
      if (!this.agreementFlag) {
        this.$toast('请阅读并同意《运输条款》《隐私政策》')
        return
      }
      setOrderForm(this.newStepArr)
      providerInfoAllN(this, this.newStepArr, this.isBatch, 'mr', this.callbackProviderList)
    },
    callbackProviderList(isNext) {
      if (isNext !== true) return
      if (this.newStepArr.drawerType === 'add' || !this.newStepArr.orderSn) {
        //新增单跳到选择供应商页
        this.$router.push({
          name: 'lineGoods',
          params: {
            oneDatas: this.newStepArr,
            isBatch: this.isBatch || false,
            faIdx: this.faIdx
          }
        })
      } else {
        //修改单和再来一单直接跳到订单确认页
        this.$router.push({
          name: 'confirmOrder',
          params: {
            oneDatas: this.newStepArr,
            isBatch: this.isBatch || false,
            faIdx: this.faIdx
          }
        })
      }
    },
    jumpToBatchForm() {
      this.$router.push({
        path: '/m_batchorder'
      })
    },
    jumpToPage(type) {
      this.$router.push({
        name: 'm_orderagreement',
        meta: {
          title: type === '1' ? '运输条款' : type === '2' ? '隐私政策' : '收货标准'
        },
        params: {
          tabType: type || '1',
          path: 'm_payorder',
          allDatas: this.newStepArr
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.padTp30 {
  padding-top: 30px;
}
.mt30 {
  margin-top: 30px;
}
.padlr24 {
  padding: 0 24px;
}
.size26 {
  font-size: 26px;
  padding: 5px;
  line-height: 34px;
  border-radius: 5px;
}
.swiper_box ::v-deep .van-swipe-item {
  width: auto !important;
  margin-right: 10px !important;
}
.tag {
  padding: 10px 30px 10px 20px;
  border-radius: 12px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  font-family: Alibaba PuHuiTi;
  &.alive {
    background-color: #1989fa;
    color: #fff;
  }
}
.borRight {
  border-right: 1px solid #dbdcdb;
}
.ctx1 ::v-deep .van-cell__value .van-field__body {
  &:after {
    content: '件';
    font-size: px;
    color: #666;
  }
}
.ctx2 ::v-deep > .van-cell__value .van-field__body {
  &:after {
    content: '公斤';
    width: 42px;
    display: inline-block;
    font-size: px;
    color: #666;
  }
}
.ctx3 ::v-deep > .van-cell__value .van-field__body {
  &:after {
    content: '方';
    font-size: px;
    color: #666;
  }
}
.h35 {
  padding: 5px 10px;
}
.bgW {
  background-color: #fff;
}
.borBt {
  border-bottom: 1px solid #dbdcdb;
}
.indent8 {
  text-indent: 16px;
  background-color: #fff;
  padding-top: 10px;
}
.col {
  padding: 15px 0;
  background-color: #fff;
}
.tips {
  width: 100%;
  color: #666;
  margin-top: 30px;
  span {
    color: #2a97e8;
  }
}
.reflex ::v-deep .van-cell__title {
  flex: revert;
}
.providerbox {
  padding: 10px 20px;
  .protitle {
    padding-left: 10px;
    border-left: 3px solid #2a97e8;
  }
  .procont {
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 10px;
    .protype {
      padding: 10px;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .prodate {
      padding: 10px;
      color: #fff;
      background-color: #2a97e8;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
</style>
